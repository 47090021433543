<template>
  <div>
    <a-form layout="inline" :model="searchFormState" style="margin-bottom: 20px;">
      <a-form-item label="标题">
        <a-input v-model:value="searchFormState.curriculumTitle" placeholder="请输入标题" class="searchInputWidth"></a-input>
      </a-form-item>

      <a-form-item label="作者">
        <a-input
          v-model:value="searchFormState.curriculumAuthor"
          placeholder="请输入作者"
          class="searchInputWidth"
        ></a-input>
      </a-form-item>

      <a-form-item label="上传人">
        <a-input
          v-model:value="searchFormState.userName"
          placeholder="请输入上传人"
          class="searchInputWidth"
        ></a-input>
      </a-form-item>

      <a-form-item label="分类">
        <a-tree-select
          v-model:value="searchFormState.menuId"
          show-search
          placeholder="请选择分类"
          allow-clear
          style="width: 220px;"
          :tree-data="sechRolesOptions"
          :replaceFields="replaceFields"
        >
          <template #title="{ value: val, title }">
            <b v-if="val === 'parent 1-1'" style="color: #08c">sss</b>
            <template v-else>{{ title }}</template>
          </template>
        </a-tree-select>
      </a-form-item>
      <a-form-item style="margin-left: 20px;">
        <a-button type="primary" @click="search(searchFormState)" style="border-radius: 4px;">
          <template #icon>
            <search-outlined />
          </template>
          查询
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="clearFormState(searchFormState)">
          <template #icon>
            <redo-outlined />
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="mb-20">
      <a-button type="primary" @click="operate('add')">
        <template #icon>
          <plus-outlined />
        </template>
        新增
      </a-button>

      <!-- <a-button @click="batchOperate('delete')" class="ml20">
        <template #icon></template>
        批量删除
      </a-button>-->
    </div>

    <!-- 表格数据展示 -->
    <a-table
      :columns="columns"
      :data-source="tabaleData"
      bordered
      :pagination="false"
      :rowKey="item => item.id"
    >
      <template #curriculumImage="{ record }">
        <a-image
          :height="30"
          :width="30"
          :src="`http://114.116.236.37:9001/${record.curriculumImage}`"
        />
      </template>
      <template #recommendStatus="{ record }">
        <a-popconfirm
          :title="`${record.recommendStatus ? '开启' : '取消' }推荐`"
          ok-text="确认"
          cancel-text="取消"
          @confirm="simpleStopUse(record)"
        >
        <a-switch
          :checked="record.recommendStatus === 0"
          checked-children="是"
          un-checked-children="否"
          default-checked
        />
        </a-popconfirm>
        
      </template>
      <template #curriculumStatus="{ record }">
        <a-popconfirm
          :title="`确认${record.curriculumStatus === 0 ?  '下架' : '上架' }`"
          ok-text="确认"
          cancel-text="取消"
          @confirm="stopUseUp(record)"
        >
        <a-switch
          :checked="record.curriculumStatus === 0"
          checked-children="上架"
          un-checked-children="下架"
          default-checked
        />
        </a-popconfirm>
      </template>
      <template #operation="{ record }">
        <a style="color: #637CFE;" @click="operate('edit', record)">编辑</a>
        <a-divider type="vertical" />
        <!-- <a style="color: #637CFE;" @click="operate('delete', record)">删除</a> -->
        <a-popconfirm
          title="确认删除该分类？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="operate('delete', record)"
        >
          <a-button type="link" size="small">删除</a-button>
        </a-popconfirm>
      </template>
      <template #userEnable="{ record }">
        <div style="display: flex;align-items: center;justify-content: center;">
          <span :style="record.userEnable != 1 ? 'color: #6A6A6A;' : 'color: #D2D2D2;'">停用</span>
          <a-switch
            v-model:checked="record.switch"
            style="margin: 0 7px;"
            @change="simpleStopUse(record)"
          />
          <span :style="record.userEnable == 1 ? 'color: #6A6A6A;' : 'color: #D2D2D2;'">可用</span>
        </div>
      </template>
    </a-table>

    <div style="margin-top: 40px;display: flex;flex-direction: column;align-items: flex-end;">
      <a-pagination
        v-model:current="pagination.current"
        :total="pagination.total"
        v-if="tabaleData.length"
        @change="paginationChange"
        v-model:pageSize="pagination.pageSize"
        show-size-changer
        @showSizeChange="onShowSizeChange"
        :page-size-options="pagination.pageSizeOptions"
        :show-total="total => `共 ${total} 条`"
        show-quick-jumper
      />
    </div>

    <modal
      ref="customDialog"
      :title="(modalState ? '新增' : '编辑') + '课程'"
      :width="1300"
      @handleOk="sumbitEditForm(uploadState.fileList, uploadState.videoFileList)"
      @handleCancel="modalCancel"
    >
      <!-- .fileList[0].response.data[0].path -->
      <a-form
        name="custom-validation"
        ref="formRef"
        :model="formState"
        :rules="formStateRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="课程标题" name="curriculumTitle">
          <a-input
            v-model:value="formState.curriculumTitle"
            autocomplete="off"
            placeholder="请输入课程标题"
          />
        </a-form-item>
        <a-form-item
          label="课程图片"
          name="headPortrait"
          style="margin-bottom:5px"
          class="curriculumImg"
          extra="上传格式为：JPG和PNG；推荐图片尺寸为：164X96"
        >
          <a-upload
            v-model:file-list="uploadState.fileList"
            :action="uploadState.fileurl"
            list-type="picture-card"
            name="mfile"
            :data="{ type: 1 }"
            accept="image/png, image/jpeg"
            @preview="handlePreview"
            :before-upload="beforeUploadImg"
          >
            <div v-if="uploadState.fileList.length < 1">
              <plus-outlined />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <span
            class="uptext"
            v-if="uploadState.imgFile && uploadState.fileList.length === 0"
          >请选择课程图片</span>
          <!-- <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>-->
        </a-form-item>
        <a-form-item label="作者" name="curriculumAuthor">
          <a-input
            v-model:value="formState.curriculumAuthor"
            autocomplete="off"
            placeholder="请输入作者"
          />
        </a-form-item>

        <a-form-item label="分类" name="menuId">
          <a-tree-select
            v-model:value="formState.menuId"
            show-search
            placeholder="请选择分类"
            allow-clear
            style="width: 100%;"
            :tree-data="sechRolesOptions"
            :replaceFields="replaceFields"
          >
            <template #title="{ value: val, title }">
              <b v-if="val === 'parent 1-1'" style="color: #08c">sss</b>
              <template v-else>{{ title }}</template>
            </template>
          </a-tree-select>
        </a-form-item>

        <a-form-item label="视频上传" name="curriculumVideo" class="culumVideo">
          <a-upload
            v-model:file-list="uploadState.videoFileList"
            list-type="picture"
            accept="video/mp4"
            :action="uploadState.videoFileurl"
            name="mfile"
            @preview="handlePreview"
            :data="{ type: 2 }"
            :before-upload="beforeUploadVideo"
          >
            <div v-if="uploadState.videoFileList.length < 1">
              <div class="ant-upload-text">
                <a-button>
                  <plus-outlined />上传视频
                </a-button>
              </div>
            </div>
          </a-upload>
          <span
            class="uptextVideo"
            v-if="uploadState.videoFile && uploadState.videoFileList.length === 0"
          >请选择课程视频</span>
          <!-- <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <video :src="previewVideo"></video>
          </a-modal>-->
        </a-form-item>

        <a-form-item label="课程介绍" name="courseIntroduce" class="culumVideo">
          <div ref="editor"></div>
          <span class="editorTips" v-if="editorTips">请输入课程介绍</span>
        </a-form-item>
      </a-form>
    </modal>
    <!-- 预览 -->
    <a-modal
      v-if="previewData.previewVisible"
      :visible="previewData.previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        v-if="previewData.previewState"
        alt="example"
        style="width: 100%"
        :src="previewData.previewImage"
      />
      <video v-else controls loop style="width: 100%">
        <source :src="previewData.previewVideo" />
      </video>
    </a-modal>
  </div>
</template>

<script>
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined
} from '@ant-design/icons-vue';
// import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, reactive, nextTick, ref, onMounted } from 'vue';
import Modal from '@/components/modal.vue'
import { myPost, myGet } from "@/common/request.js"
import WangEditor from 'wangeditor';
import { fileUrlVerification } from "@/utils";

const api = {
  // 查询
  queryPageUser: '/xkcwebApi/srCurriculum/querySrCurriculum.htm',
  // 新增用户
  addSrCurriculum: '/xkcwebApi/srCurriculum/addSrCurriculum.htm',
  // 修改
  editSrCurriculum: '/xkcwebApi/srCurriculum/updateSrCurriculum.htm',
  // 删除用户
  deleteUserUrl: '/xkcwebApi/srCurriculum/deleteSrCurriculumById.htm',
  // 上传图片
  updatePicture: '/api/common/upload/upload.htm',
  // 是否推荐
  recommend: '/xkcwebApi/srCurriculum/updateRecommendStatusParam.htm',
    // 单个上架或者下架
  simpleUseUrl: '/xkcwebApi/srCurriculum/updateCurriculumStatusParam.htm',
  // 查询分类
  queryClassiTree: '/xkcwebApi/srClassification/querySrClassificationTree.htm',
  // 上传视频
  updateVideo: "/api/common/common/find_path.htm"
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default defineComponent({
  components: {
    SearchOutlined,
    RedoOutlined,
    PlusOutlined,
    Modal
  },
  setup() {
    const previewData = reactive({
      previewState: true,
      previewVisible: false,
      previewImage: '',
      previewVideo: ''
    })

    const customDialog = ref(null)
    const searchFormState = reactive({})
    const editorTips = ref(false)

    // 搜索查询
    const search = () => {
      queryList(1, searchFormState)
    };

    let formState = reactive({
      curriculumTitle: '',
      curriculumAuthor: '',
      menuId: ''
    })

    const formStateRules = {
      curriculumTitle: [
        {
          required: true,
          message: '请输入课程标题',
          trigger: ['blur', 'change'],
          whitespace: true,
          type: 'string'
        }
      ],
      curriculumAuthor: [
        {
          required: true,
          message: '请输入作者',
          trigger: ['blur', 'change'],
          whitespace: true,
          type: 'string'
        }
      ],
      menuId: [
        {
          required: true,
          message: '请选择分类',
          trigger: ['blur', 'change'],
          whitespace: true,
          type: 'string'
        }
      ]
    }
    let uploadState = reactive({
      imgFile: false,
      fileurl: `http://114.116.236.37:8094${api.updatePicture}`,
      // fileurl: `http://${window.location.host}${api.updatePicture}`,
      fileList: [],
      videoFile: false,
      videoFileurl: `http://114.116.236.37:8094${api.updatePicture}`,
      // videoFileurl: `http://${window.location.host}${api.updatePicture}`,
      videoFileList: [],
    });
    const imageUrl = ref('');

    const editor = ref(null);
    const content = reactive({
      html: '',
      text: '',
    });

    let instance;
    let editorInit = () => {
      instance = new WangEditor(editor.value);
      instance.config.excludeMenus = [
        'emoticon',
        'todo',
        'link',
        'video',
        'table'
      ]
      instance.config.showLinkImg = false
      instance.config.showLinkImgAlt = false
      instance.config.showLinkImgHref = false
      instance.config.uploadImgMaxSize = 2 * 1024 * 1024 // 2M
      instance.config.uploadFileName = 'mfile'
      instance.config.uploadImgParams = {
        type: 1
      }
      // 图片返回格式不同，这里需要根据后端提供的接口进行调整
      instance.config.uploadImgHooks = {
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function (insertImgFn, result) {
          console.log('result', result)
          // result 即服务端返回的接口
          // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
          if (result && result.code === 200) {
            // insertImgFn函数接收的是返回图片的全地址，eg:http://img.xxx.com/image/20211025/96759ce5aab246e6860f72a364e4142a.jpg
            insertImgFn(`http://114.116.236.37:9001/${result.data[0].path}`)
            // 多图时，循环调用insertImgFn
            // result.result.forEach(item => insertImgFn(item.url))
          }
        }
      }
      // 你自己上传图片的接口地址
      instance.config.uploadImgServer = `http://114.116.236.37:8094${api.updatePicture}`;
      // instance.config.uploadImgServer = `http://${window.location.host}${api.updatePicture}`;
      // Object.assign(instance.config, {
      //   onchange() {
      //     console.log('发生变化，富文本内容为：', instance.txt.html())
      //   }
      // })

      instance.create();
    };

    const sumbitEditForm = (file, Videofile) => {
      // uploadState.imgFile = true
      formRef.value.validate().then(() => {
        if (file.length === 0) {
          uploadState.imgFile = true
        } else if (Videofile.length === 0) {
          uploadState.videoFile = true
        } else {
          if (instance.txt.html() === '') {
            editorTips.value = true
          } else {
            editorTips.value = false
            if (modalState.value) {
              let tempParam = {
                ...formState,
                curriculumImage: file[0].response.data[0].path,
                curriculumVideo: Videofile[0].response.data[0].path,
                curriculumMemo: instance.txt.html(),
                menuId: formState.menuId
              }
              myPost(api.addSrCurriculum, tempParam).then((res) => {
                if (res.code === 200) {
                  customDialog.value.closeModal()
                  message.success('新增成功')
                  modalCancel()
                }
              })
                .catch((err) => {
                  console.log(err);
                })
            } else {
              let tempParam = {
                ...formState,
                curriculumImage: fileUrlVerification(file),
                curriculumVideo: fileUrlVerification(Videofile),
                curriculumMemo: instance.txt.html(),
                menuId: formState.menuId
              }
              myPost(api.editSrCurriculum, tempParam).then((res) => {
                if (res.code === 200) {
                  customDialog.value.closeModal()
                  message.success('编辑成功')
                  modalCancel()
                  // queryList('', searchFormState)
                }
              })
                .catch((err) => {
                  console.log(err);
                })
            }
          }

        }

      })
        .catch((err) => {
          console.log(err);
        })
    }
    // 清除表单
    const clearFormState = (form, type) => {
      for (var i in form) {
        form[i] = null
      }
      type != 'handle' && queryList(1)
    }

    const sechRolesOptions = ref()
    const queryTree = () => {
      myPost(api.queryClassiTree, { id: "" }).then((res) => {
        if (res.code === 200) {
          sechRolesOptions.value = res.data.srClassificationList
        }
      })
        .catch((err) => {
          console.log(err);
        })
    }

    const changeOptions = (id) => {
      myPost(api.querySrClass, { id: id }).then(res => {
        message.success(res.message)
      })
    }

    const modalState = ref(true)

    const columns = [
      {
        title: '课程标题',
        dataIndex: 'curriculumTitle',
        key: 'courseHeading',
        align: 'center'
      },
      {
        title: '课程图片',
        dataIndex: 'curriculumImage',
        key: 'coursePicture',
        align: 'center',
        slots: {
          customRender: 'curriculumImage',
        }
      },

      {
        title: '作者',
        dataIndex: 'curriculumAuthor',
        key: 'curriculumAuthor',
        align: 'center'

      },
      {
        title: '上传人',
        dataIndex: 'userName',
        align: 'center'
      },
      {
        title: '维护时间',
        dataIndex: 'updateTime',
        align: 'center'
      },
      {
        title: '是否推荐',
        dataIndex: 'recommendStatus',
        align: 'center',
        slots: {
          customRender: 'recommendStatus',
        }
      },
      {
        title: '课程状态',
        dataIndex: 'curriculumStatus',
        align: 'center',
        slots: {
          customRender: 'curriculumStatus',
        }
      },
      {
        title: '操作',
        dataIndex: 'operation',
        slots: {
          customRender: 'operation',
        },
        align: 'center',
      }
    ];

    // 表格数据
    const tabaleData = ref([])
    tabaleData.value = [
      {
        curriculumAuthor: null,
        curriculumImage: "",
        curriculumMemo: null,
        curriculumStatus: null,
        curriculumTitle: "1",
        curriculumVideo: null,
        curriculumViews: null,
        id: 1,
        menuId: "",
        recommendStatus: 1,
        title: "",
        updateTime: "",
        userId: null,
        userName: null
      }
    ]
    const formRef = ref('')
    const operate = (type, record) => {
      modalState.value = type == 'add' ? true : false;
      if (type == 'edit') {
        customDialog.value.showModal()
        formState = Object.assign(formState, record)
        uploadState.fileList = [
          {
            uid: '1',
            url: `http://114.116.236.37:9001/${record.curriculumImage}`
          }
        ]
        uploadState.videoFileList = [
          {
            uid: '2',
            url: `http://114.116.236.37:9001/${record.curriculumVideo}`,
            name: `${record.curriculumTitle}课程视频`
          }
        ]
        nextTick(() => {
          editorInit()
          instance.txt.append(record.curriculumMemo)
        })
      } else if (type == 'add') {
        customDialog.value.showModal();
        nextTick(() => {
          editorInit()
        })
      } else if (type == 'delete') {
        myGet(api.deleteUserUrl, { id: record.id }).then(res => {
          message.success(res.message)
          queryList('', searchFormState)
        })
      }
    }

    const modalCancel = () => {
      uploadState.fileList = [];
      uploadState.videoFileList = [];
      formRef.value && formRef.value.resetFields();
      clearFormState(formState)
      instance.destroy();
      instance = null;
    }

    const queryList = (cur, condition = {}) => {
      if (cur) {
        pagination.value.current = cur
      }
      let param = {
        page: pagination.value.current,
        size: pagination.value.pageSize,
        ...condition
      }
      myPost(api.queryPageUser, param).then((res) => {
        if (res.code === 200) {
          tabaleData.value = res.data
        }
        pagination.value.total = res.total
      }).catch((err) => {
        console.log(err);
      })
    }
    onMounted(() => {
      queryTree()
      queryList(1)
    })

    // 分页
    const pagination = ref({
      total: 50,
      current: 1,
      pageSize: 10,
      pageSizeOptions: ['10', '20', '30', '40', '50']
    })

    // 推荐
    const simpleStopUse = (record) => {
      
      myPost(api.recommend, { id: record.id, recommendStatus: record.recommendStatus ? 0 : 1 }).then(res => {
        message.success(res.message)
        queryList(1, searchFormState)
      })
    }

    // 课程状态 上下架
    const stopUseUp = (record) => {
      console.log(record);
      myPost(api.simpleUseUrl, { id: record.id, curriculumStatus: record.curriculumStatus ? 0 : 1 }).then(res => {
        message.success(res.message)
        queryList(1, searchFormState)
      })
    }


    const paginationChange = (page, pageSize) => {
      pagination.value.page = page
      pagination.value.pageSize = pageSize
      queryList('', searchFormState)
    }
    const onShowSizeChange = (page, pageSize) => {
      pagination.value.page = page
      pagination.value.pageSize = pageSize
    }

    const handleCancel = () => {
      previewData.previewVisible = false;
      queryList('', searchFormState)
    };

    const handlePreview = async file => {
      if (modalState.value) {
        if (file.type.includes('image')) {
          previewData.previewState = true;
          file.preview = await getBase64(file.originFileObj);
          previewData.previewImage = `${file.url || file.preview}`;
        } else {
          previewData.previewState = false;
          previewData.previewVideo = `http://114.116.236.37:8094/${file.response.data[0].path}`;
        }
      } else {
        if (file.uid !== '2') {
          previewData.previewState = true;
          previewData.previewImage = file.url;
        } else {
          previewData.previewState = false;
          previewData.previewVideo = file.url;
        }
      }
      previewData.previewVisible = true;
    };

    const beforeUploadImg = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('请选择正确的图片!');
      }
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   message.error('Image must smaller than 2MB!');
      // }
      return isJpgOrPng
    };

    const beforeUploadVideo = file => {
      const isJpgOrPng = file.type === 'video/mp4'
      if (!isJpgOrPng) {
        message.error('请选择正确的视频!');
      }
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   message.error('Image must smaller than 2MB!');
      // }
      return isJpgOrPng
    };

    // const handleChange = ({fileList: newFileList}) => {
    //   uploadState.fileList = newFileList;
    // };

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      searchFormState,
      replaceFields: {
        children: 'sunTreeMenu',
        curriculumTitle: 'title',
        key: 'id',
        value: 'id'
      },
      sechRolesOptions,
      customDialog,
      modalState,
      formStateRules,
      sumbitEditForm,
      modalCancel,
      search,
      clearFormState,
      formState,
      formRef,
      uploadState,
      imageUrl,
      editor,
      content,
      tabaleData,
      columns,
      paginationChange,
      onShowSizeChange,
      pagination,
      operate,
      simpleStopUse,
      stopUseUp,
      changeOptions,
      previewData,
      handleCancel,
      handlePreview,
      editorTips,
      beforeUploadImg,
      beforeUploadVideo
    }
  }
})
</script>

<style lang="scss" scoped>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #bfbfbf;
}
.searchInputWidth {
  width: 220px;
}
.ml20 {
  margin-left: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.curriculumImg {
  position: relative;
}
.curriculumImg::before {
  position: absolute;
  left: 135px;
  top: 13px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.culumVideo {
  position: relative;
}
.culumVideo::before {
  position: absolute;
  left: 135px;
  top: 13px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.ant-upload-picture-card-wrapper {
  zoom: 1;
  display: inline-block;
  width: 100%;
  position: relative;
}
.uptext {
  color: #ff4d4f;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
}
.uptextVideo {
  color: #ff4d4f;
  font-size: 14px;
  position: absolute;
  line-height: 23px;
}

.editorTips {
  color: #ff4d4f;
  width: 200px;
  font-size: 14px;
  position: absolute;
  line-height: 25px;
}
</style>
