/**
 * @description: 拆箱函数,解决tooltip显示问题
 * @param {*} obj
 * @return {*}
 */
 export const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);

 export const fileUrlVerification = (file) => {
     // eslint-disable-next-line no-prototype-builtins
   return (file[0].hasOwnProperty('response')) ? (file[0].response.data[0].path) : ((file[0].url).slice((file[0].url).indexOf('/xingkecheng/') + 1, (file[0].url).length))
  };